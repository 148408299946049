import { useContext } from "react";

// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const regNum = /[^a-zA-Z0-9 ]/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var numFeild = /^[+-]?\d+$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
// const { dictionary } = useContext(LanguageContext);
// console.log(messages);
const data = JSON.parse(localStorage.getItem("userData"));
const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};
const checkNumber = (value) => {
  if (/^[0-9]+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};
const checkAlphaNumeric = (value) => {
  if (/^[a-zA-Z0-9]+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const loginValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Username or Email";
  }
  // else if (checkEmail(values.email)) {
  //   errors.email = 'Invalid Email'
  // }
  if (!values.password) {
    errors.password = "Enter Password";
  }
  return errors;
};
export const forgotValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Username or Email";
  }
  return errors;
};
export const otpValidator = (values) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  } else if (values.otp.length > 4) {
    errors.otp = "Invalid OTP";
  }
  return errors;
};
export const resetValidator = (values, lang) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password does not match";
  }
  return errors;
};

export const customerListValidation = (values) => {
  let errors = {};

  if (!values.customer_code) {
    errors.customer_code = "Enter Customer Code";
  } else if (!checkNumber(values.customer_code)) {
    errors.customer_code = "Invalid Customer Code";
  }

  if (!values.customer_name) {
    errors.customer_name = "Enter Customer Name";
  }

  if (!values.customer_number) {
    errors.customer_number = "Contact number is required";
  } else if (!/^[0-9]{10}$/.test(values.customer_number)) {
    errors.customer_number = "Contact number must be 10 digits";
  }
  if (!values.customer_email) {
    errors.customer_email = "Enter Email ID";
  } else if (checkEmail(values.customer_email)) {
    errors.customer_email = "Invalid Email ID";
  }
  if (!values.customer_address) {
    errors.customer_address = "Enter Customer Address";
  }

  if (!values.dark_logo) {
    errors.dark_logo = "Upload Dark theme Logo";
  }
  if (!values.light_logo) {
    errors.light_logo = "Upload Light Theme Logo";
  }

  if (!values.customer_status) {
    errors.customer_status = "Select Status";
  }
  if (!values.timezone) {
    errors.timezone = "Select Timezone";
  }
  if (!values.pped_in_dropdown) {
    errors.pped_in_dropdown = "Enter Pay Period End Date";
  } else if (!/^\d+$/.test(values.pped_in_dropdown)) {
    errors.pped_in_dropdown =
      "Pay Period End Date must be a positive integer or zero";
  }

  return errors;
};

const checkBasePayRate = (value) => {
  // Allow positive integers or decimal numbers with up to two decimal places
  if (/^[0-9]+(\.[0-9]{1,5})?$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const portalUserValidation = (values) => {
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  const specialCharRegex2 = /^[a-zA-Z0-9]*[!@#$?]+[a-zA-Z0-9]*$/;
  const uppercaseRegex = /.*[A-Z].*/;
  const lowercaseRegex = /.*[a-z].*/;
  const numberRegex = /\d/;
  const whitespaceRegex = /\s/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@?#$']).{8,12}$/;
  let errors = {};

  if (!values.employee_name) {
    errors.employee_name = "Name is Required";
  }
  if (!values.employee_email) {
    errors.employee_email = "Email is Required";
  }
  if (!values.username) {
    errors.username = "Username is Required";
  }
  if (!values.sid) {
    errors.sid = "Sid is Required";
  }
  if (!values.sid_email) {
    errors.sid_email = "Sid email is Required";
  }
  if (values.user_type === "") {
    errors.user_type = "User Type is required";
  }
  // if (values.base_pay_rate !== "") {
  //   if (!checkNumber(values.base_pay_rate)) {
  //     errors.base_pay_rate = "Number only";
  //   }
  // }
  // if (values.base_pay_rate !== "") {
  //   if (!checkBasePayRate(values.base_pay_rate)) {
  //     errors.base_pay_rate = "Invalid format";
  //   }
  // }
  if (values.password !== "") {
    if (!specialCharRegex.test(values.password)) {
      errors.password = "Add at least one of them !@?#$";
    }

    if (!numberRegex.test(values.password)) {
      errors.password = "Add at least one number";
    }
    if (!uppercaseRegex.test(values.password)) {
      errors.password = "Add at least one Uppercase Letter";
    }
    if (!lowercaseRegex.test(values.password)) {
      errors.password = "Add at least one Lowercase Letter";
    }

    if (whitespaceRegex.test(values.password)) {
      errors.password = "Remove whitespaces";
    }
  }

  return errors;
};
export const ApplicationListValidation = (values) => {
  let errors = {};

  if (!values.application_id) {
    errors.application_id = "Application Id is Required";
  } else if (!checkNumber(values.application_id)) {
    errors.application_id = "Numbers only";
  }
  if (!values.title) {
    errors.title = "Title is Required";
  }
  if (!values.logo) {
    errors.logo = "Logo is Required";
  }
  if (!values.sort) {
    errors.sort = "Enter Sort";
  } else if (!checkNumber(values.sort)) {
    errors.sort = "Numbers only";
  }

  return errors;
};
export const FacilityListValidation = (values) => {
  let errors = {};

  if (!values.facility_id) {
    errors.facility_id = "Facility Id is required";
  } else if (!checkNumber(values.facility_id)) {
    errors.facility_id = "Numbers only";
  }
  if (!values.facility_name) {
    errors.facility_name = "Facility Name is Required";
  }
  if (!values.timezone) {
    errors.timezone = "Select Timezone";
  }
  return errors;
}

export const CategoryMappingValidation = (values) => {
  let errors = {};

  // if (!values.facility_id) {
  //   errors.facility_id = "Facility Id is required";
  // } else if (!checkNumber(values.facility_id)) {
  //   errors.facility_id = "Numbers only";
  // }
  if (!values.field_name) {
    errors.field_name = "Field Name is Required";
  } else if (!/^[a-z0-9_]+$/.test(values.field_name)) {
    errors.field_name =
      "Field Name must contain only lowercase letters, numbers, and underscores, with no spaces or special characters";
  }
  
  if (!values.field_type) {
    errors.field_type = "Select Field Type";
  }
  return errors;
};

export const CostCenterCategoryValidation = (values) => {
  let errors = {};

  if (!values.cost_center_category_id) {
    errors.cost_center_category_id = "Required field";
  } else if (!checkNumber(values.cost_center_category_id)) {
    errors.cost_center_category_id = "Numbers only";
  }
  if (!values.cost_center_category) {
    errors.cost_center_category = "Required field";
  }
  if (!values.note) {
    errors.note = "Required field";
  }
  return errors;
};
const checktarget = (value) => {
  // Allow positive integers or decimal numbers with up to two decimal places
  if (/^[0-9]+(\.[0-9]{1,9})?$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const departmentValidation = (values) => {
  let errors = {};

  if (!values.cost_center_number) {
    errors.cost_center_number = "Enter Cost Center Code";
  } else if (!checkNumber(values.cost_center_number)) {
    errors.cost_center_number = "Invalid Cost Center Code";
  }

  if (!values.cost_center_name) {
    errors.cost_center_name = "Enter Cost Center Name";
  }
  if (!values.facility_id && values.facility_id === "") {
    errors.facility_id = "This field is required";
  }
  if (values.fixed_or_variable === "") {
    errors.fixed_or_variable = "This field is required";
  }
  if (values.census_grid === "") {
    errors.census_grid = "Required field";
  }
  // if (values.standard_unit_ratio === "") {
  //   errors.standard_unit_ratio = "This field is required";
  // }
  // if (values.cost_center_category === "") {
  //   errors.cost_center_category = "This field is required";
  // }

  if (!values.target) {
    errors.target = "Enter target";
  } else if (!checktarget(values.target)) {
    errors.target = "Invalid format";
  }
  if (values.accepted_acuity_per_nurse !== "") {
    if (!checktarget(values.accepted_acuity_per_nurse)) {
      errors.accepted_acuity_per_nurse = "Invalid format";
    }
  }
  if(!values.overtime_threshold){
    errors.overtime_threshold = "Required"
  }
  if (values.overtime_threshold !== "") {
    if (!checktarget(values.overtime_threshold)) {
      errors.overtime_threshold = "Invalid format";
    }
  }
  if (!values.warm_up) {
    errors.warm_up = "This field is required";
  } else if (!checkNumber(values.warm_up)) {
    errors.warm_up = "Numbers only";
  }
  if (!values.number_of_days || values.number_of_days === "") {
    errors.number_of_days = "This field is required";
  }
  if (values.realtime === "1") {
    if (!values.budget1 || !values.budget1.beds) {
      errors.budget1 = { ...errors.budget1, beds: "Beds is required" };
    }
    if (!values.budget1 || !values.budget1.fte_max) {
      errors.budget1 = { ...errors.budget1, fte_max: "Fte max is required" };
    }
    if (!values.budget1 || !values.budget1.fte_min) {
      errors.budget1 = { ...errors.budget1, fte_min: "Fte min is required" };
    }
    if (!values.budget1 || !values.budget1.nurse_uos) {
      errors.budget1 = {
        ...errors.budget1,
        nurse_uos: "Nurse Uos is required",
      };
    }
    if (!values.budget1 || !values.budget1.required_fte) {
      errors.budget1 = {
        ...errors.budget1,
        required_fte: "Required Fte is required",
      };
    }

    // Add more validation rules for other budget1 fields if needed
  }
  if (values.realtime === "0") {
    // errors.budget1 = null;
    errors.budget1 = {
      beds: null,
      fte_max: null,
    };
  }
  // if (!checktarget(values.target)) {
  //   errors.target = "Invalid format";
  // }
  const allBudget1ErrorsNull = Object.values(errors.budget1 || {}).every(
    (error) => error === null || error === undefined
  );

  // If all properties under budget1 are null or undefined, remove the budget1 key
  if (allBudget1ErrorsNull) {
    delete errors.budget1;
  }

  return errors;
};

export const AdminValidation = (values) => {
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  const specialCharRegex2 = /^[a-zA-Z0-9]*[!@#$?]+[a-zA-Z0-9]*$/;
  const uppercaseRegex = /.*[A-Z].*/;
  const lowercaseRegex = /.*[a-z].*/;
  const numberRegex = /\d/;
  const whitespaceRegex = /\s/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@?#$']).{8,12}$/;
  let errors = {};

  if (!values.employee_id) {
    errors.employee_id = "Employee id is required";
  }
  if (!values.employee_name) {
    errors.employee_name = "Employee name is required";
  }
  if (!values.employee_email) {
    errors.employee_email = "Employee email is required";
  }
  if (!values.username) {
    errors.username = "Username is required";
  }

  if (!values.sid) {
    errors.sid = "Sid is Required";
  }
  if (!values.sid_email) {
    errors.sid_email = "Sid email is Required";
  }

  if (values.password !== "") {
    if (!specialCharRegex.test(values.password)) {
      errors.password = "Add at least one of them !@?#$";
    }

    if (!numberRegex.test(values.password)) {
      errors.password = "Add at least one number";
    }
    if (!uppercaseRegex.test(values.password)) {
      errors.password = "Add at least one Uppercase Letter";
    }
    if (!lowercaseRegex.test(values.password)) {
      errors.password = "Add at least one Lowercase Letter";
    }

    if (whitespaceRegex.test(values.password)) {
      errors.password = "Remove whitespaces";
    }
  }

  return errors;
};

export const helpCenterValidation = (values) => {
  let errors = {};

  if (values.application_id === "") {
    errors.application_id = "This field is required";
  }
  if (!values.title) {
    errors.title = "Title is required";
  }
  if (!values.sort) {
    errors.sort = "Sort is Required";
  } else if (!checkNumber(values.sort)) {
    errors.sort = "Numbers only";
  }
  if (!values.description) {
    errors.description = "Description is required";
  }

  if (!values.attachment) {
    errors.attachment = "Attachment is required";
  }

  return errors;
};

export const payPeriodValidation = (values) => {
  let errors = {};

  if (!values.name) {
    errors.name = "name is required";
  } else if (!checkAlphaNumeric(values.name)) {
    errors.name = "Only alphanumerics and spaces are allowed.";
  }
  if (!values.pay_period_hours) {
    errors.pay_period_hours = "Pay Period hours is required";
  } else if (!checkNumber(values.pay_period_hours)) {
    errors.pay_period_hours = "Numbers only";
  }
  if (!values.pay_period_sequence) {
    errors.pay_period_sequence = "Pay Period Sequence is required";
  } else if (!checkNumber(values.pay_period_sequence)) {
    errors.pay_period_sequence = "Numbers only";
  }
  if (!values.begin_date) {
    errors.begin_date = "Begin Date is required";
  }

  if (!values.end_date) {
    errors.end_date = "End Date is required";
  }
  if (!values.fy_sequence) {
    errors.fy_sequence = "FY Sequence is required";
  } else if (!checkNumber(values.fy_sequence)) {
    errors.fy_sequence = "Numbers only";
  }
  if (!values.fiscal_year) {
    errors.fiscal_year = "Fiscal Year is required";
  }
  if (!values.calendar_year) {
    errors.calendar_year = "Calendar Year is required";
  }

  return errors;
};

export const LocationListValidation = (values) => {
  let errors = {};

  if (!values.location_id) {
    errors.location_id = "Location Id is Required";
  } else if (!checkNumber(values.location_id)) {
    errors.location_id = "Numbers only";
  }
  if (!values.location) {
    errors.location = "Location is Required";
  }
  if (!values.status) {
    errors.status = "Status is Required";
  }
  if (!values.category) {
    errors.category = "Category is required";
  }
  if (!values.cost_center_number) {
    errors.cost_center_number = "Cost Center is required";
  }

  return errors;
};
export const ReasonListValidation = (values) => {
  let errors = {};

  if (!values.reason_id) {
    errors.reason_id = "Reason Id is Required";
  } else if (!checkNumber(values.reason_id)) {
    errors.reason_id = "Numbers only";
  }
  if (!values.reason) {
    errors.reason = "Reason is Required";
  }
  if (!values.status) {
    errors.status = "Status is Required";
  }
  if (!values.category) {
    errors.category = "Category is required";
  }
  if (!values.cost_center_number) {
    errors.cost_center_number = "Cost Center is required";
  }
  return errors;
};
export const NursingGridValidation = (values) => {
  let errors = {};

  if (!values.cost_center_number) {
    errors.cost_center_number = "Required";
  }
  if (!values.shift_time) {
    errors.shift_time = "Required";
  }
  if (!values.year) {
    errors.year = "Required";
  }

  return errors;
};

export const PaycodeFormValidation = (values) => {
  let errors = {};

  if (!values.interface_code) {
    errors.interface_code = "Paycode is required";
  }
  if (!values.description) {
    errors.description = "Description is Required";
  }
  if (!values.client_mapping) {
    errors.client_mapping = "Client Mapping is Required";
  }
  if (!values.ps_mapping) {
    errors.ps_mapping = "PS Mapping is Required";
  }
  return errors;
}




export const SettingsValidation = (values) => {
  let errors = {};

  if (!values.key) {
    errors.key = "Key is Required";
  } else if (!/^[a-z0-9_]+$/.test(values.key)) {
    errors.key =
      "Key must contain only lowercase letters, numbers, and underscores, with no spaces or special characters";
  }

  if (!values.value) {
    errors.value = "Value is Required";
    // errors.field_type = "Select Field Type";
  }

  // if (!values.customer_code) {
  //   errors.customer_code = "Select Customer Code";
  // }

  // if (!values.status) {
  //   errors.status = "Select Status";
  // }

  // Check for begin_date and end_date validation
  if (!values.start_date) {
    errors.start_date = "Start date is required";
  }

  if (!values.end_date) {
    errors.end_date = "End date is required";
  } else if (values.start_date && values.end_date < values.start_date) {
    errors.end_date = "End date cannot be before the Start date";
  }

  if (!values.start_time) {
    errors.start_time = "Start Time is required";
  }

  if (!values.end_time) {
    errors.end_time = "End Time is required";
  }

  return errors;
};