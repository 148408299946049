import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import moment from "moment";
import {
  SettingsValidation,
  portalUserValidation,
} from "../../utils/validator";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import CROSS from "../../../src/assets/img/close.png";
import { setLoadingPage } from "../../store/actions";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Quill styles

function AddPayPeriodForm() {
  const dispatch = useDispatch();
  const { employeeID } = useParams();
  const { ID } = useParams();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");
  const navigate = useNavigate();
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterData, setCostCenterData] = useState(null);
  const [costCenterFilteredData, setCostCenterFilteredData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [dropUserType, setDropUserType] = useState(null);
  const currentYear = new Date().getFullYear();
  const [CustomerCodeOptions, setCustomerCodeOptions] = useState([]);
  const years = Array.from({ length: 4 }, (_, index) => currentYear + index);
  console.log("date", beginDate, endDate);

  const [initialValues, setInitialValues] = useState({
    key: "",
    value: "",
    customer_code: "",
    status: "1",
    start_date: null,
    end_date: null,
    start_time: "",
    end_time: "",
  });


  console.log("into", initialValues);
  

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  };

  useEffect(() => {
    const getCustomerCode = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/customer/customerHeaderList`
      );
      console.log(resp);
      const Detail = resp?.data?.data?.data;
      setCustomerCodeOptions(Detail);
      console.log("Detail", Detail);

    };
    getCustomerCode();
  }, []);

  // const performSearch = (data, query) => {
  //   if (query.trim() === "") {
  //     return data || [];
  //   } else {
  //     return (data || []).filter((e) =>
  //       e.cost_center_name.toLowerCase().includes(query.toLowerCase())
  //     );
  //   }
  // };

  const performSearch = (data, query) => {
    if (query.trim() === "") {
      return data || [];
    } else {
      return data.filter(
        (e) =>
          e.cost_center_name.toLowerCase().includes(query.toLowerCase()) ||
          e.cost_center_number.toString().includes(query.toLowerCase())
      );
    }
  };

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      customer_code: values.customer_code,
      key: values.key,
      value: values.value,
      status: values.status,
      start_date: moment(values.start_date).format("DD-MM-YYYY"),
      end_date: moment(values.end_date).format("DD-MM-YYYY"),
      start_time: moment(values.start_time)?.format("HH:mm:ss"),
      end_time: moment(values.end_time)?.format("HH:mm:ss"),
    };
    try {
      setIsLoading(true);
      const resp = await axios.put(
        `${API_CALL_URL_PRIVATE}/setting/update/${ID}`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/settings");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US"); // Force using US locale
  }

    useEffect(() => {
      const getInitialData = async () => {
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/setting/detail/${ID}`,
        );
        console.log(resp);
        const Detail = resp?.data?.data;
        setInitialValues((prev) => {
          return {
            ...prev,
            key: Detail?.key,
            value: Detail?.value,
            customer_code: Detail?.customer_code,
            status: Detail?.status,
            start_date: moment(Detail?.start_date, "DD-MM-YYYY").toDate(),
            end_date: moment(Detail?.end_date, "DD-MM-YYYY").toDate(),
            start_time: Detail.start_time
              ? moment(Detail.start_time, "HH:mm:ss").toDate()
              : null,
            end_time: Detail.end_time
              ? moment(Detail.end_time, "HH:mm:ss").toDate()
              : null,
          };
        });
        setISInitialData(true);
      };
      getInitialData();
    }, []);

  console.log("CustomerCodeOptions", CustomerCodeOptions);

  return (
    <>
      {isInitialData && !loading_page ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={SettingsValidation}
            enableReinitialize
            validateOnChange
          >
            {(formik) => (
              <div className="main-panel">
                {console.log(formik)}
                <div className="content-wrapper">
                  <div className="page-header">
                    <h3 className="page-title">
                      <a href="#" className="badge badge-dark"></a>
                    </h3>
                    <div className></div>
                  </div>
                  <div className="row">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <FormikForm className="forms-sample">
                          <div class="card-body">
                            <div className="row align-items-center">
                              <div className="col-3">
                                <Link
                                  to={`/admin/settings`}
                                  className="f-14 white-text-dark-mode"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                  Back
                                </Link>
                              </div>
                              <div className="col-6">
                                <h4 class="card-title text-center">
                                  Update Settings
                                </h4>
                              </div>
                            </div>

                            <div class="row">
                              <Form.Group
                                className="col-md-4 mb-3"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Key</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="key"
                                  value={formik.values.key}
                                  {...formik.getFieldProps("key")}
                                />
                                <ErrorMessage
                                  name="key"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-4 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Customer</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="customer_code"
                                  value={`${formik.values.customer_code}`}
                                  onChange={(e) => {
                                    formik.setFieldTouched(
                                      "customer_code",
                                      true,
                                      true
                                    );
                                    formik.setFieldValue(
                                      "customer_code",
                                      e.target.value
                                    );
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value={""}>Select</option>
                                  {CustomerCodeOptions?.map((e) => {
                                    return (
                                      <option
                                        key={e?.customer_code}
                                        value={e?.customer_code}
                                      >
                                        {e?.customer_name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <ErrorMessage
                                  name="customer_code"
                                  component={Texterror}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-md-4 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="status"
                                  value={formik.values.status}
                                  onChange={(e) => {
                                    formik.setFieldTouched(
                                      "status",
                                      true,
                                      true
                                    );
                                    if (e.target.value !== "") {
                                      formik.setFieldValue(
                                        "status",
                                        e.target.value
                                      );
                                    }
                                    // formik.setFieldValue(
                                    //   "customer_status",
                                    //   e.target.value
                                    // );
                                    // console.log(e.target.value);
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched(
                                      "status",
                                      true,
                                      true
                                    );
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="status"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-3 mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <DatePicker
                                  selected={formik.values.start_date}
                                  onChange={(date) =>
                                    formik.setFieldValue("start_date", date)
                                  }
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control"
                                  placeholderText="Select start date"
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>

                              <Form.Group className="col-md-3 mb-3">
                                <Form.Label>Start Time</Form.Label>
                                <DatePicker
                                  selected={formik.values.start_time}
                                  onChange={(time) => {
                                    // Convert to local time format
                                    const localTime = moment(time).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    formik.setFieldValue("start_time", time);
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  dateFormat="h:mm aa"
                                  className="form-control"
                                  placeholderText="Select start time"
                                />
                                <ErrorMessage
                                  name="start_time"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>

                              {/* End Date Field */}
                              <Form.Group className="col-md-3 mb-3">
                                <Form.Label>End Date</Form.Label>
                                <DatePicker
                                  selected={formik.values.end_date}
                                  onChange={(date) =>
                                    formik.setFieldValue("end_date", date)
                                  }
                                  minDate={
                                    formik.values.start_date || new Date()
                                  }
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control"
                                  placeholderText="Select end date"
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>

                              <Form.Group className="col-md-3 mb-3">
                                <Form.Label>End Time</Form.Label>
                                <DatePicker
                                  selected={formik.values.end_time}
                                  onChange={(time) => {
                                    const localTime = moment(time)?.format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    formik.setFieldValue("end_time", time);
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  dateFormat="h:mm aa"
                                  className="form-control"
                                  placeholderText="Select end time"
                                />
                                <ErrorMessage
                                  name="end_time"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-12 mb-3"
                                controlId="exampleForm.c_name"
                              >
                                <Form.Label>Value</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  placeholder=""
                                  name="value"
                                  {...formik.getFieldProps("value")}
                                />
                                <ErrorMessage
                                  name="value"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                                {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </Form.Group>
                            </div>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddPayPeriodForm;
