import { Provider } from "react-redux";
import { createMemoryHistory } from "history";
import {
  BrowserRouter,
  Route,
  Routes,
  redirect as Redirect,
  Navigate,
} from "react-router-dom";
import { store } from "./store";
import Home from "./pages/Home/index";
import LoginPage from "./pages/auth/login/index";
import DirectEntry from "./pages/DirectEntry/index";
import Dashboard from "./pages/Dashboard/index";
import Customer from "./pages/Customer/index";
import Application from "./pages/Application/index";
import AddApplicationForm from "./components/AddApplicationForm/index";
import EditApplicationForm from "./components/EditApplicationForm/index";
import Department from "./pages/Department/index";
import TargetType from "./pages/TargetType/index";
import Setting from "./pages/Setting/index";
import Facility from "./pages/Facility/index";
import AddFacilityForm from "./components/AddFacilityForm/index";
import AddTargetTypeForm from "./components/AddTargetTypeForm/index";
import EditFacilityForm from "./components/EditFacilityForm/index";
import HelpCenter from "./pages/HelpCenter/index";
import CommentManagement from "./pages/CommentManagement/index";
import AddHelpCenter from "./components/AddHelpCenter/index";
import EditHelpCenter from "./components/EditHelpCenter/index";
import CustomerForm from "./components/CustomerForm/index";
import AddCustomerForm from "./components/AddCustomerForm";
import ActiveManagement from "./pages/ActiveManagment/index";
import FormControl from "./pages/FormControl/index";
import AdminUser from "./pages/AdminUser/index";
import AddAdminUser from "./components/AddAdminUser";
import EditAdminUser from "./components/EditAdminUser/index";
import AddFormControl from "./components/AddFormControl/index";
import EditCostCenterForm from "./components/EditCostCenterForm/index";
import EditCostNewCenterForm from "./components/EditNewCostCenter/index";
import EditCostCenterApp from "./components/EditCostCenterApp/index";
import EditCostCenterDrag from "./components/EditCostCenterDrag/index";
import "./App.css";
import { useEffect, useState, useContext } from "react";
import Forgot from "./pages/auth/forgot";
import Reset from "./pages/auth/reset";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext, ThemeProvider } from "./providers/ThemeProvider";
import { Helmet } from "react-helmet";
import ThemeStyle from "./Styled/ThemeStyle";
import PageLayout from "./layouts/PageLayout";
import EditCustomerForm from "./components/EditCustomerForm/index";
import PortalUser from "./pages/PortalUser/index";
import EditPortalUserForm from "./components/EditPortalUserForm/index";
import { ToastContainer } from "react-toastify";
import AddPortalUserForm from "./components/AddPortalUser/index";
import AddCostCenterForm from "./components/AddCostCenterForm/index";
import AddNewCostCenter from "./components/AddNewCostCenter/index";
import AddNewCostCenterApp from "./components/AddNewCostCenterApp/index";
import AddCostCenterDrag from "./components/AddCostCenterDrag/index";
import UserActivity from "./pages/UserActivity/index";
import AdminActivity from "./pages/AdminActivity/index";
import FileActivity from "./pages/FileActivity";
import PayPeriod from "./pages/PayPeriod";
import AddPayPeriodForm from "./components/AddPayPeriod";
import EditPayPeriodForm from "./components/EditPayPeriod";
import AddSettings from "./components/AddSettings";
import EditSettings from "./components/EditSettings";
import LocationMaster from "./pages/LocationMaster/index";
import AddLocationMasterForm from "./components/AddLocationMaster/index";
import EditLocationMasterForm from "./components/EditLocationMaster/index";
import ReasonMaster from "./pages/ReasonMaster";
import AddReasonMaster from "./components/AddReasonMaster";
import EditReasonMaster from "./components/EditReasonMaster";
import DirectData from "./pages/DirectData/index";
import AddDirectData from "./components/AddDirectData/index";
import NursingGrid from "./pages/NursingGrid";
import AddNursingGrid from "./components/AddNursingGrid";
import EditNursingGridForm from "./components/EditNursingGridForm/index";
import AddNursingGridForm from "./components/AddNursingGridForm/index";
import EditNursingGridNewForm from "./components/EditNursingGridNewForm";
import NursingGridNew from "./pages/NursingGridNew";
import AddNursingGridNewForm from "./components/AddNursingGridNewForm";
import CostCenterCategory from "./pages/CostCenterCategory/index";
import AddCostCenterCategory from "./components/AddCostCenterCategory";
import EditCostCenterCategory from "./components/EditCostCenterCategory";
import EditTargetType from "./components/EditTargetType";
import Paycode from "./pages/Paycode";
import AddPaycodeForm from "./components/AddPaycode/index";
import EditPaycodeForm from "./components/EditPaycode/index";
import CategoryMapping from "./pages/CategoryMapping/index";
import AddCategoryMapping from "./components/AddCategoryMapping";
import EditCategoryMapping from "./components/EditCategoryMapping";

const history = createMemoryHistory();

const PublicRoute = () => {
  let navigate = useNavigate();
  let location = useNavigate();
  const [theme, setTheme] = useState("light");
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />
      <Route
        exact
        path="/forgot"
        element={<Forgot history={navigate} location={location} />}
      />
      <Route
        exact
        path="/reset"
        element={<Reset history={navigate} location={location} />}
      />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
const PrivateRoute = () => {
  let navigate = useNavigate();
  return (
    <Routes>
      <Route path="/admin" element={<PageLayout />}>
        <Route path="home" element={<Dashboard />} />
        {/* <Route path="/realtime" element={<Home />} /> */}
        <Route path="customer" element={<Customer />} />
        <Route
          path="/admin/customer/edit-customer/:customerID"
          element={<EditCustomerForm />}
        />

        <Route
          path="/admin/customer/add-customer"
          element={<AddCustomerForm />}
        />
        <Route path="application" element={<Application />} />
        <Route
          path="/admin/application/add-application"
          element={<AddApplicationForm />}
        />
        <Route
          path="/admin/application/edit-application/:ID/:AppID"
          element={<EditApplicationForm />}
        />

        <Route path="targettype" element={<TargetType />} />
        <Route
          path="/admin/targettype/add-targettype"
          element={<AddTargetTypeForm />}
        />
        <Route
          path="/admin/targettype/edit-targettype/:id"
          element={<EditTargetType />}
        />
        <Route path="facility" element={<Facility />} />
        <Route
          path="/admin/facility/add-facility"
          element={<AddFacilityForm />}
        />
        <Route
          path="/admin/facility/edit-facility/:ID"
          element={<EditFacilityForm />}
        />
        <Route path="department" element={<Department />} />
        <Route
          path="/admin/department/edit-department/:ID/:costCenter"
          element={<EditCostCenterForm />}
        />
        <Route
          path="/admin/department/edit-department-new/:ID/:costCenter"
          element={<EditCostCenterApp />}
        />
        <Route
          path="/admin/department/edit-department-drag/:ID/:costCenter"
          element={<EditCostCenterDrag />}
        />
        <Route
          path="/admin/department/add-department-new"
          element={<AddNewCostCenterApp />}
        />
        <Route
          path="/admin/department/add-department-drag"
          element={<AddCostCenterDrag />}
        />
        <Route
          path="/admin/department/edit-new-department/:ID/:costCenter"
          element={<EditCostNewCenterForm />}
        />
        <Route
          path="/admin/department/add-department"
          element={<AddCostCenterForm />}
        />
        <Route
          path="/admin/department/add-new-department"
          element={<AddNewCostCenter />}
        />
        <Route
          path="/admin/cost-center-category"
          element={<CostCenterCategory />}
        />
        <Route
          path="/admin/cost-center-category/add-category"
          element={<AddCostCenterCategory />}
        />
        <Route
          path="/admin/cost-center-category/edit-category/:id"
          element={<EditCostCenterCategory />}
        />
        <Route path="portal-user" element={<PortalUser />} />
        <Route
          path="/admin/portal-user/edit-user/:ID/:employeeID/:customerCode"
          element={<EditPortalUserForm />}
        />
        <Route
          path="/admin/portal-user/add-user"
          element={<AddPortalUserForm />}
        />
        <Route path="form-control" element={<FormControl />} />
        <Route
          path="/admin/form-control/add-control"
          element={<AddFormControl />}
        />
        <Route path="admin-user" element={<AdminUser />} />
        <Route path="/admin/admin-user/add-admin" element={<AddAdminUser />} />
        <Route
          path="/admin/admin-user/edit-admin/:employeeID/:ID"
          element={<EditAdminUser />}
        />
        <Route path="help-center" element={<HelpCenter />} />
        <Route
          path="/admin/help-center/add-helpcenter"
          element={<AddHelpCenter />}
        />
        <Route
          path="/admin/help-center/edit-helpcenter/:ID"
          element={<EditHelpCenter />}
        />
        <Route path="user-activity" element={<UserActivity />} />
        <Route path="admin-activity" element={<AdminActivity />} />
        <Route path="file-activity" element={<FileActivity />} />
        <Route path="pay-period" element={<PayPeriod />} />
        <Route
          path="/admin/pay-period/add-payperiod"
          element={<AddPayPeriodForm />}
        />
        <Route
          path="/admin/pay-period/edit-payperiod/:ID"
          element={<EditPayPeriodForm />}
        />
        <Route path="location-master" element={<LocationMaster />} />
        <Route
          path="/admin/location-master/add-location"
          element={<AddLocationMasterForm />}
        />
        <Route
          path="/admin/location-master/edit-location/:ID"
          element={<EditLocationMasterForm />}
        />
        <Route path="reason-master" element={<ReasonMaster />} />
        <Route
          path="/admin/reason-master/add-reason"
          element={<AddReasonMaster />}
        />
        <Route
          path="/admin/reason-master/edit-reason/:ID"
          element={<EditReasonMaster />}
        />

        <Route path="direct-data" element={<DirectData />} />
        <Route
          path="/admin/direct-data/import-direct-data"
          element={<AddDirectData />}
        />
        <Route path="comment-center" element={<CommentManagement />} />

        <Route path="settings" element={<Setting />} />
        <Route path="/admin/settings/add-settings" element={<AddSettings />} />
        <Route
          path="/admin/settings/edit-settings/:ID"
          element={<EditSettings />}
        />
        {/* <Route path="nursing-grid" element={<NursingGrid />} />
        <Route
          path="/admin/nursing-grid/import-nursing-grid"
          element={<AddNursingGrid />}
        />
        <Route
          path="/admin/nursing-grid/add-nursing-grid"
          element={<AddNursingGridForm />}
        />
        <Route
          path="/admin/nursing-grid/edit-nursing-grid/:ID"
          element={<EditNursingGridForm />}
        /> */}
        <Route
          path="/admin/staffing-grid/import-staffing-grid"
          element={<AddNursingGrid />}
        />
        <Route path="staffing-grid" element={<NursingGridNew />} />
        <Route
          path="/admin/staffing-grid/edit-staffing-grid/:ID"
          element={<EditNursingGridNewForm />}
        />
        <Route
          path="/admin/staffing-grid/add-staffing-grid"
          element={<AddNursingGridNewForm />}
        />
        <Route path="/admin/paycode" element={<Paycode />} />
        <Route path="/admin/paycode/add-paycode" element={<AddPaycodeForm />} />
        <Route
          path="/admin/paycode/edit-paycode/:ID"
          element={<EditPaycodeForm />}
        />

        <Route path="category-mapping" element={<CategoryMapping />} />
        <Route
          path="/admin/category-mapping/add"
          element={<AddCategoryMapping />}
        />
        <Route
          path="/admin/category-mapping/edit/:ID"
          element={<EditCategoryMapping />}
        />
        {/* <Route
          path="/admin/facility/add-facility"
          element={<AddFacilityForm />}
        />
        <Route
          path="/admin/facility/edit-facility/:ID"
          element={<EditFacilityForm />}
        /> */}

        {/* <Route path="/activedailymanagement" element={<ActiveManagement history={navigate}/>} /> */}
        {/* <Route
        path="/activedailymanagement/exceptionsummary/:department"
        element={<ExceptionsDetails history={navigate}/>}
      />
      <Route
        path="/realtime/department-details/:idlabel/:urltype"
        element={<DirectEntry />}
      /> */}
      </Route>
      <Route path="/*" element={<Navigate to="/admin/home" />} />
    </Routes>
  );
};

const StyleTag = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  return (
    <Helmet>
      <style>{ThemeStyle(themeMode.theme)}</style>
    </Helmet>
  );
};

function App() {
  const userData = useSelector((state) => state.compensate.userData);
  return (
    <ThemeProvider>
      <StyleTag />
      <div className="start">
        {userData ? <PrivateRoute userData={userData} /> : <PublicRoute />}
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
