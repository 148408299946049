import Chart from "react-apexcharts";

function PieChart({data}) {
    //console.log("piechart", data);
    const values = data.map(item => item.value);
    const labels = data.map(item => item.label + ' ' + item.ratio);
    //console.log("values", values);
    //console.log("labels", labels);

    const options = {
        chart: {
            width: 285,
            height: 750,
            position: 'center',
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        labels: labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            },
            breakpoint: 1441,
            options: {
                chart: {
                    width: 200
                },
            }
        }],
        legend: {
            position: 'bottom',
            offsetY: 0,
        },
        tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              return '<div style="background:#fff; color:#000">'+w.config.labels[seriesIndex]+'</div>';
                //console.log("data chart", w);
            }
        }
    }

    return (
        <div className="m-auto">
            <Chart
              options={options}
              series={values}
              type="donut" 
              width={'100%'}
              height={750}
            />
        </div>
    );
}

export default PieChart;