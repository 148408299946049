import React, { useEffect, useState, useContext, useRef } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { BiCommentError } from "react-icons/bi";
import { MdOutlineShareLocation } from "react-icons/md";
import {
  AiOutlineDashboard,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineBgColors,
} from "react-icons/ai";
import { MdOutlineCategory } from "react-icons/md";
import { GoDatabase } from "react-icons/go";
import { RiCouponLine } from "react-icons/ri";
import { GrUserAdmin } from "react-icons/gr";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { RxActivityLog } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { FaClipboardList, FaBloggerB } from "react-icons/fa";
import { SiBrandfolder } from "react-icons/si";
import { BiCategoryAlt } from "react-icons/bi";
import { Layout, Menu, theme } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../";
import Prlogo from "../assets/img/perfectshift-logo-dark.png";
import MainLogo from "../assets/img/perfectshift-logo.png";
import PrlogoSmall from "../assets/img/perfectshift-logo-dark-mobile.png";
import Dropdown from "react-bootstrap/Dropdown";
import {
  API_CALL_URL_PRIVATE,
  DOMAIN_URL,
  USER_PORTAL,
} from "../constants/compensate";
import axios from "../axios";
import { get, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerCode, setLoadingPage, setNavigate } from "../store/actions";
import { RiAdminLine } from "react-icons/ri";
import ThemeSwitcher from "../components/ThemeSwitcher";
import { ThemeContext } from "../providers/ThemeProvider";
import { THEME_TYPE } from "../constants/theme";
import { RiSunLine } from "react-icons/ri";

import MenuIcon1 from "../assets/img/dark.png";
import { IoLogoBuffer } from "react-icons/io";
import MenuIcon2 from "../assets/img/setting-light.png";
import MenuIcon22 from "../assets/img/setting-dark.png";

import MenuIcon3 from "../assets/img/logout-light.png";
import MenuIcon33 from "../assets/img/logout-dark.png";

const { Header, Sider, Content } = Layout;
const PageLayout = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  console.log(userDatas, "userData");
  const [collapsed, setCollapsed] = useState(false);
  const [customersList, setCustomersList] = useState(null);
  const [dropDownItemUrl, setDropDownItemUrl] = useState("");
  const [currentHeader, setCurrentHeader] = useState("");
  const [customerName, setCustomerName] = useState(null);

  const userGlobal = useSelector((state) => state.compensate.userData.data);
  const [themeMode, setTheme] = useContext(ThemeContext);

  console.log(currentHeader);
  const { pathname } = useLocation();
  const firstSlug = pathname.split("/")[2];
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    const getCustomerList = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/customer/customerHeaderList`
      );
      console.log(resp);
      setCustomersList(resp?.data?.data?.data);
      setDropDownItemUrl(`${DOMAIN_URL}/${resp?.data?.data?.data[0]?.logo}`);
      if (localStorage.getItem("customerCode")) {
        const data = localStorage.getItem("customerCode");
        const temp = resp?.data?.data?.data?.filter(
          (e) => e?.customer_code === parseInt(data)
        );
        console.log(temp);
        setCurrentHeader(temp[0]?.customer_code);
        setCustomerName(temp[0]?.customer_name);
        dispatch(setCustomerCode(temp[0]?.customer_code));
      } else {
        setCurrentHeader(resp?.data?.data?.data[0]?.customer_code);
        setCustomerName(resp?.data?.data?.data[0]?.customer_name);
        dispatch(setCustomerCode(resp?.data?.data?.data[0]?.customer_code));
        localStorage.setItem(
          "customerCode",
          resp?.data?.data?.data[0]?.customer_code
        );
      }
      // setCurrentHeader(resp?.data?.data?.data[0]?.customer_code);
      // setCustomerName(resp?.data?.data?.data[0]?.customer_name);
      // dispatch(setCustomerCode(resp?.data?.data?.data[0]?.customer_code));
      // localStorage.setItem(
      //   "customerCode",
      //   resp?.data?.data?.data[0]?.customer_code
      // );
    };
    getCustomerList();
  }, []);
  const handleClickLoginBtn = async () => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/portalLogin/login`,
        {
          customer_code: currentHeader,
        }
      );
      return resp.data;
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!isEmpty(userData)) {
      const storedTheme = userData.data?.theme_mode;
      if (storedTheme === 1) {
        setTheme({ theme: THEME_TYPE.DARK });
      } else {
        setTheme({ theme: THEME_TYPE.LIGHT });
      }
    }
  }, []);

  const handleThemeChange = async (e) => {
    if (themeMode.theme === THEME_TYPE.LIGHT) {
      setTheme({ theme: THEME_TYPE.DARK });
    } else {
      setTheme({ theme: THEME_TYPE.LIGHT });
    }
    const themeType = themeMode.theme === THEME_TYPE.LIGHT ? 1 : 0;

    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/portalUser/updateThemeMode`,
        { theme_mode: themeType }
      );
      localStorage.setItem("psAdmin", JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  const userLogout = async () => {
    try {
      const resp = await axios.post(`${API_CALL_URL_PRIVATE}/logout`);

      window.localStorage.clear();
      window.sessionStorage.clear();
      navigate("/");
      window.location.reload();

      toast.success(resp.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  function handleClick(e, path) {
    e.preventDefault(); // Prevent default anchor link behavior
    if (path == "signout") {
    } else {
      navigate(path);
    }
    // Use navigate to change path
  }

  return (
    <Layout /* onContextMenu={(e) => e.preventDefault()} */>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center  mb-0">
            <span className="sm-logo">
              <img src={PrlogoSmall} />
            </span>
            <span className="lg-logo">
              {/* <img width="70%" src={Prlogo} /> */}
              <img
                width="70%"
                src={themeMode.theme === "DARK" ? Prlogo : MainLogo}
                alt=""
                class="dekstop-only h-26"
              />
              {/* <img src={themeMode.theme === "DARK" ? MainLogoDarkMobile : MainLogoMobile} alt="" class="mobile-only" /> */}
            </span>
          </h2>
        </div>

        <Menu
          className="mt-4"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[firstSlug]}
          // onClick={({ key }) => {
          //   if (key == "signout") {
          //   } else {
          //     console.log("ddd", key);
          //     navigate(key);
          //   }
          // }}
          items={[
            {
              className: "nothover pointer-event-none",
              label: "Navigation",
            },
            {
              key: "home",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: (
                <a href="/admin/home" onClick={(e) => handleClick(e, "home")}>
                  Dashboard
                </a>
              ),
            },
            {
              key: "application",
              icon: <AiOutlineShoppingCart className="fs-4" />,
              label: (
                <a
                  href="/admin/application"
                  onClick={(e) => handleClick(e, "application")}
                >
                  Application
                </a>
              ),
              // children: [
              //   {
              //     key: "product",
              //     icon: <AiOutlineShoppingCart className="fs-4" />,
              //     label: "Add Product",
              //   },
              //   {
              //     key: "list-product",
              //     icon: <AiOutlineShoppingCart className="fs-4" />,
              //     label: "Product List",
              //   },
              //   {
              //     key: "brand",
              //     icon: <SiBrandfolder className="fs-4" />,
              //     label: "Brand",
              //   },
              //   {
              //     key: "list-brand",
              //     icon: <SiBrandfolder className="fs-4" />,
              //     label: "Brand List ",
              //   },
              //   {
              //     key: "category",
              //     icon: <BiCategoryAlt className="fs-4" />,
              //     label: "Category",
              //   },
              //   {
              //     key: "list-category",
              //     icon: <BiCategoryAlt className="fs-4" />,
              //     label: "Category List",
              //   },
              //   {
              //     key: "color",
              //     icon: <AiOutlineBgColors className="fs-4" />,
              //     label: "Color",
              //   },
              //   {
              //     key: "list-color",
              //     icon: <AiOutlineBgColors className="fs-4" />,
              //     label: "Color List",
              //   },
              // ],
            },
            {
              key: "customer",
              icon: <AiOutlineUser className="fs-4" />,
              label: (
                <a
                  href="/admin/customer"
                  onClick={(e) => handleClick(e, "customer")}
                >
                  Customer
                </a>
              ),
            },
            {
              key: "targettype",
              icon: <FaClipboardList className="fs-4" />,
              label: (
                <a
                  href="/admin/targettype"
                  onClick={(e) => handleClick(e, "targettype")}
                >
                  Target Type
                </a>
              ),
            },
            {
              key: "facility",
              icon: <FaClipboardList className="fs-4" />,
              label: (
                <a
                  href="/admin/facility"
                  onClick={(e) => handleClick(e, "facility")}
                >
                  Facility
                </a>
              ),
            },
            {
              key: "department",
              icon: <RiCouponLine className="fs-4" />,
              label: (
                <a
                  href="/admin/department"
                  onClick={(e) => handleClick(e, "department")}
                >
                  Cost Centers
                </a>
              ),
              // children: [
              //   {
              //     key: "coupon",
              //     icon: <ImBlog className="fs-4" />,
              //     label: "Add Coupon",
              //   },
              //   {
              //     key: "coupon-list",
              //     icon: <RiCouponLine className="fs-4" />,
              //     label: "Coupon List",
              //   },
              // ],
            },
            {
              key: "cost-center-category",
              icon: <MdOutlineCategory className="fs-4" />,
              label: (
                <a
                  href="/admin/cost-center-category"
                  onClick={(e) => handleClick(e, "cost-center-category")}
                >
                  Cost Center Categories
                </a>
              ),
            },
            {
              key: "category-mapping",
              icon: <MdOutlineCategory className="fs-4" />,
              label: (
                <a
                  href="/admin/category-mapping"
                  onClick={(e) => handleClick(e, "category-mapping")}
                >
                  Category Mapping
                </a>
              ),
            },
            {
              key: "portal-user",
              icon: <FaBloggerB className="fs-4" />,
              label: (
                <a
                  href="/admin/portal-user"
                  onClick={(e) => handleClick(e, "portal-user")}
                >
                  Users
                </a>
              ),
              // children: [
              //   {
              //     key: "blog",
              //     icon: <ImBlog className="fs-4" />,
              //     label: "Add Blog",
              //   },
              //   {
              //     key: "blog-list",
              //     icon: <FaBloggerB className="fs-4" />,
              //     label: "Blog List",
              //   },
              //   {
              //     key: "blog-category",
              //     icon: <ImBlog className="fs-4" />,
              //     label: "Add Blog Category",
              //   },
              //   {
              //     key: "blog-category-list",
              //     icon: <FaBloggerB className="fs-4" />,
              //     label: "Blog Category List",
              //   },
              // ],
            },
            // {
            //   key: "admin-user",
            //   icon: <RiAdminLine className="fs-4" />,
            //   label: "Admin User",
            // },
            {
              key: "help-center",
              icon: <IoIosHelpCircleOutline className="fs-4" />,
              label: (
                <a
                  href="/admin/help-center"
                  onClick={(e) => handleClick(e, "help-center")}
                >
                  Help Center
                </a>
              ),
            },
            {
              key: "user-activity",
              icon: <RxActivityLog className="fs-4" />,
              label: (
                <a
                  href="/admin/user-activity"
                  onClick={(e) => handleClick(e, "user-activity")}
                >
                  Platform Activity
                </a>
              ),
            },
            {
              key: "admin-activity",
              icon: <RxActivityLog className="fs-4" />,
              label: (
                <a
                  href="/admin/admin-activity"
                  onClick={(e) => handleClick(e, "admin-activity")}
                >
                  Config Activity
                </a>
              ),
            },
            {
              key: "file-activity",
              icon: <RxActivityLog className="fs-4" />,
              label: (
                <a
                  href="/admin/file-activity"
                  onClick={(e) => handleClick(e, "file-activity")}
                >
                  File Activity
                </a>
              ),
            },
            {
              key: "pay-period",
              icon: <RxActivityLog className="fs-4" />,
              label: (
                <a
                  href="/admin/pay-period"
                  onClick={(e) => handleClick(e, "pay-period")}
                >
                  Pay Period
                </a>
              ),
            },
            {
              key: "location-master",
              icon: <MdOutlineShareLocation className="fs-4" />,
              label: (
                <a
                  href="/admin/location-master"
                  onClick={(e) => handleClick(e, "location-master")}
                >
                  Locations
                </a>
              ),
            },
            {
              key: "reason-master",
              icon: <BiCommentError className="fs-4" />,
              label: (
                <a
                  href="/admin/reason-master"
                  onClick={(e) => handleClick(e, "reason-master")}
                >
                  Reasons
                </a>
              ),
            },
            {
              key: "direct-data",
              icon: <GoDatabase className="fs-4" />,
              label: (
                <a
                  href="/admin/direct-data"
                  onClick={(e) => handleClick(e, "direct-data")}
                >
                  Direct Data
                </a>
              ),
            },
            {
              key: "staffing-grid",
              icon: <GoDatabase className="fs-4" />,
              label: (
                <a
                  href="/admin/staffing-grid"
                  onClick={(e) => handleClick(e, "staffing-grid")}
                >
                  Staffing Grid
                </a>
              ),
            },
            {
              key: "paycode",
              icon: <GoDatabase className="fs-4" />,
              label: (
                <a
                  href="/admin/paycode"
                  onClick={(e) => handleClick(e, "paycode")}
                >
                  Paycode
                </a>
              ),
            },
            {
              key: "comment-center",
              icon: <IoIosHelpCircleOutline className="fs-4" />,
              label: (
                <a
                  href="/admin/comment-center"
                  onClick={(e) => handleClick(e, "comment-center")}
                >
                  Comment Center
                </a>
              ),
            },
            {
              key: "setting",
              icon: <FaClipboardList className="fs-4" />,
              label: (
                <a
                  href="/admin/settings"
                  onClick={(e) => handleClick(e, "settings")}
                >
                  Settings
                </a>
              ),
            },
            // {
            //   key: "new-nursing-grid",
            //   icon: <GoDatabase className="fs-4" />,
            //   label: (
            //     <a
            //       href="/admin/new-nursing-grid"
            //       onClick={(e) => handleClick(e, "new-nursing-grid")}
            //     >
            //       New Nursing Grid
            //     </a>
            //   ),
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5 main-sect"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              style: { color: "white" },
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div class="d-flex w-100 justify-content-center align-items-center">
            {dropDownItemUrl ? (
              <Dropdown>
                <Dropdown.Toggle
                  style={{ background: "transparent", border: "0px" }}
                  id="dropdown-basic"
                >
                  {/* <img
                  src="https://greatwordpresstools.com/ps-admin-adshboard/assets/images/stanford.png"
                  alt=""
                /> */}
                  {customerName ? customerName : <span class="loader"></span>}
                  {/* {dropDownItemUrl ? (
                    <img src={dropDownItemUrl} alt="" />
                  ) : (
                    <span class="loader"></span>
                  )} */}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  variant="secondary"
                  style={{ minWidth: "220px" }}
                >
                  {customersList?.map((Ele) => {
                    return (
                      <Dropdown.Item
                        key={Ele?._id}
                        style={{
                          background:
                            Ele?.customer_code === currentHeader
                              ? "themeMode.theme === 'DARK' ? '#000' : 'white'"
                              : "transparent",
                          color: "white",
                          fontSize: "14px",
                          padding: "5px 5px",
                          lineHeight: "30px",
                        }}
                        href="#/action-1"
                        onClick={() => {
                          setDropDownItemUrl(`${DOMAIN_URL}/${Ele?.logo}`);
                          setCurrentHeader(Ele?.customer_code);
                          setCustomerName(Ele?.customer_name);
                          dispatch(setNavigate(true));
                          dispatch(setCustomerCode(Ele?.customer_code));
                          if (currentHeader !== Ele.customer_code) {
                            dispatch(setLoadingPage(true));
                          }
                          localStorage.setItem(
                            "customerCode",
                            Ele?.customer_code
                          );
                        }}
                      >
                        {/* <img
                          src={`${DOMAIN_URL}/${Ele?.logo}`}
                          alt=""
                          style={{
                            width: "90%",
                            height: "90%",
                            objectFit: "contain",
                          }}
                        /> */}
                        {Ele?.customer_name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <span class="loader"></span>
            )}

            {/* <div class="dropdown d-flex align-items-center">
                            <span class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="https://greatwordpresstools.com/ps-admin-adshboard/assets/images/stanford.png" alt="" />
                            </span>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#"><img src="https://greatwordpresstools.com/ps-admin-adshboard/assets/images/realtime.png" alt="" /></a>
                            </div>
                        </div> */}
            <div class="mx-2">
              <button
                class="add btn btn-primary todo-list-add-btn"
                onClick={async () => {
                  const resp = await handleClickLoginBtn();
                  if (resp) {
                    window.open(
                      `${USER_PORTAL}?access_token=${resp.login_access_token}`,
                      "_blank"
                    );
                  } else {
                    toast.error("Error Occured", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                    });
                  }
                }}
              >
                Login
              </button>
            </div>
          </div>
          <div className="d-flex gap-4 align-items-center">
            {/* <div className="position-relative">
                            <IoIosNotifications className="fs-4" />
                            <span className="badge bg-warning rounded-circle p-1 position-absolute">
                                3
                            </span>
                        </div> */}

            <div className="d-flex gap-3 align-items-center dropdown l-menu">
              `{" "}
              {/* <div
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "100%",
                  background: "rgb(236, 240, 241)",
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {userDatas ? `${userDatas?.short_name}` : ""}
              
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ width: "80px" }}
              >
                <h5 className="mb-0 text-light">{`${userDatas?.employee_name}`}</h5>
              
              </div>` */}
              <Dropdown className="dpdown">
                {/* <Dropdown.Toggle variant="sucess" id="dropdown-basic">

                                                </Dropdown.Toggle> */}
                <Dropdown.Toggle variant="link" className="user-d">
                  <span class="name text-uppercase">
                    {userDatas ? `${userDatas?.short_name}` : ""}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Header align="center"><span class="name">{(get(userDatas, 'first_name', '')).slice(0, 1)}{get(userDatas, 'last_name', '').slice(0, 1)}</span></Dropdown.Header> */}
                  <Dropdown.Header className="hd">
                    Hi,{" "}
                    <span className="text-capitalize">{`${userDatas?.employee_name}`}</span>
                  </Dropdown.Header>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => handleThemeChange()}>
                    {themeMode.theme === "DARK" ? (
                      <span style={{ marginRight: "13px" }}>
                        <RiSunLine style={{ fontSize: "1.5rem" }} />
                      </span>
                    ) : (
                      <img src={MenuIcon1} />
                    )}
                    {themeMode.theme === "DARK" ? "Light Mode" : "Dark Mode"}
                  </Dropdown.Item>
                  {/* <Dropdown.Divider />
                                                    <Dropdown.Item><Link
                                                        to={`/account-settings`}><img src={themeMode.theme === "DARK" ? MenuIcon22 : MenuIcon2} alt="" /> Settings</Link></Dropdown.Item> */}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={userLogout}>
                    <img
                      src={themeMode.theme === "DARK" ? MenuIcon33 : MenuIcon3}
                      alt=""
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown className="dpdown">
                <Dropdown.Toggle
                  variant="link"
                  className="user-d"
                ></Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={userLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          </div>
        </Header>
        <Content
          className="main-sect"
          style={{
            // margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          {/* {props.children} */}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default PageLayout;
